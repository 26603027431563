import { CriticalBeds } from '../../interfaces'
import { useSelector, useDispatch } from 'react-redux'
import { PatientCard } from './PatientCard/PatientCard'
import { TextField, InputAdornment } from '@mui/material'
import { CustomArrowIcon } from '../../utils/helperFunctions'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
  lazy,
  useRef,
  Suspense,
  useState,
  useEffect,
  ChangeEvent,
  ReactElement,
  MutableRefObject,
} from 'react'
import {
  APP_TYPE,
  hospitals,
  layoutOptions,
  WEBSOCKET_URL,
  noPatientAvailableText,
  thopNotificationAlertType,
} from '../../utils/constants'
import {
  setBedId,
  setLayout,
  setBedNumber,
  setSearchQuery,
  setPatientUHID,
  setAdmissionDate,
  setCurrentLocation,
  setStatusUpdateCount,
  setSelectedPatientIndex,
  setHasPatientDataLoaded,
  addThopNotificationMessage,
  setIpdUpdateCount,
  setIpdUpdateMessageLocation,
  setIpdDataUpdateCount,
} from '../../redux/actions'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import LoadingComp from '../LoadingComp/LoadingComp'
import PatientData from '../PatientData/PatientData'
import useGetAllBeds from '../../utils/useGetAllBeds'
import useGetDoctors from '../../utils/useGetDoctors'
import useGetLocations from '../../utils/useGetLocations'
import PatientDetails from '../PatientDetails/PatientDetails'
import useGetCriticalBeds from '../../utils/useGetCriticalBeds'
import VitalsContainer from '../VitalsContainer/VitalsContainer'
import useGetPatientDetails from '../../utils/useGetPatientDetails'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import './Hospitals.css'

type IpdUpdateMessage = {
  message: string
  patientLocation: string
  messageType: string
  patientID: string
}

const PatientDashboard = lazy(
  () => import('../PatientDashboard/PatientDashboard')
)
const TreatmentAndMedications = lazy(
  () => import('../TreatmentAndMedications/TreatmentAndMedications')
)

const Hospitals = (): ReactElement => {
  const dispatch = useDispatch()
  const patientDetails = useSelector((state: any) => ({
    layout: state.layout,
    searchQuery: state.searchQuery,
    patient_uhid: state.patient_uhid,
    onlyLocations: state.onlyLocations,
    currentLocation: state.currentLocation,
    patient_fhir_id: state.patient_fhir_id,
    patient_data_loaded: state.patient_data_loaded,
    masterPatientDetails: state.masterPatientDetails,
  }))

  const ipdRegistrationFlagCount = useSelector(
    (state: any) => state.ipdRegistrationFlagCount
  )
  const selectedPatientIndex = useSelector(
    (state: any) => state.selectedPatientIndex
  )
  const [newIpdUpdateMessage, setNewIpdUpdateMessage] =
    useState<IpdUpdateMessage>({
      message: '',
      patientLocation: '',
      messageType: '',
      patientID: '',
    })
  let id
  const thopSocketRef = useRef<WebSocket | null>(null)
  const patientRefs = useRef<(HTMLDivElement | null)[]>([])
  const patientCardRef = useRef() as MutableRefObject<HTMLDivElement>
  const criticalBedList = useGetCriticalBeds(
    patientDetails?.currentLocation,
    ipdRegistrationFlagCount
  )
  const { fetchPatientDetails } = useGetPatientDetails()
  const { fetchGetLocations } = useGetLocations()
  const { fetchGetDoctors } = useGetDoctors()
  const { fetchAllBeds } = useGetAllBeds()
  const [isInitialRenderForPatient, setIsInitialRenderForPatient] =
    useState<boolean>(true)
  const [isInitialRenderForSearch, setIsInitialRenderForSearch] =
    useState<boolean>(true)

  const emptyMessage = {
    patientID: 'Dummy Patient',
    messageType: 'dummy-message',
    patientLocation: 'dummy-location',
    message: 'Dummy message sent successfully',
  }

  const handleSelectedPatient = (patient: CriticalBeds, index: number) => {
    if (selectedPatientIndex !== null) {
      patientCardRef?.current?.children?.[
        selectedPatientIndex
      ]?.classList?.remove('patient-card-selected')
    }
    patientCardRef?.current?.children?.[index]?.classList?.add(
      'patient-card-selected'
    )
    dispatch(setBedId(patient?.id))
    dispatch(setBedNumber(patient?.bed_number))
    dispatch(setAdmissionDate(patient?.admission_date))
    dispatch(setSelectedPatientIndex(index))
    fetchPatientDetails(patient?.patient_id)
  }

  const clearPatientDetails = () => {
    dispatch(setPatientUHID(''))
    setSearchQuery('')
    dispatch(setPatientUHID(''))
    dispatch(setSelectedPatientIndex(null))
    dispatch(setHasPatientDataLoaded(false))
  }

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(event?.target?.value))
  }

  const filteredBedList = criticalBedList?.filter(
    (patient) =>
      patient?.patient_name
        ?.toLowerCase()
        ?.includes(patientDetails?.searchQuery?.toLowerCase()) ||
      patient?.patient_id
        ?.toLowerCase()
        ?.includes(patientDetails?.searchQuery?.toLowerCase()) ||
      patient?.kimsMrNumber
        ?.toLowerCase()
        ?.includes(patientDetails?.searchQuery?.toLowerCase())
  )

  const createThopNotificationSocketConnection = () => {
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket/notifications?appType=${APP_TYPE}`
    )

    thopSocketRef.current = socket

    socket.onmessage = (event) => {
      const message = JSON.parse(event?.data)
      if (
        message?.messageType === thopNotificationAlertType?.acceptCareplan ||
        message?.messageType === thopNotificationAlertType?.rejectCareplan
      ) {
        dispatch(addThopNotificationMessage(message))
        dispatch(setStatusUpdateCount())
      }
      if (
        message?.messageType ===
          thopNotificationAlertType?.newIpdRegistrationUpdate ||
        message?.messageType ===
          thopNotificationAlertType?.deleteIpdRegistration ||
        message?.messageType === thopNotificationAlertType?.newIpdDataUpdate
      ) {
        id = patientDetails?.patient_uhid
        setNewIpdUpdateMessage(message)
      }
    }
    socket.onerror = (event) => {
      console.error('WebSocket error observed:', event)
    }

    socket.onclose = (event) => {
      console.log('WebSocket closure code:', event.code)
      if (event.code !== 1000 && event.code !== 1001) {
        console.log('WebSocket closed abnormally. Reconnecting...')
        createThopNotificationSocketConnection()
      }
    }
  }

  useEffect(() => {
    dispatch(setSelectedPatientIndex(null))
    const patientsList = patientDetails?.masterPatientDetails?.find(
      (subArray: { patientId: string }[]) =>
        subArray?.some(
          (patient: { patientId: string }) => patient?.patientId === id
        )
    )

    const patientIndex = patientsList?.findIndex(
      (item: { patientId: string }) => item?.patientId === id
    )

    if (selectedPatientIndex !== null) {
      patientCardRef?.current?.children?.[
        selectedPatientIndex
      ]?.classList?.remove('patient-card-selected')
    }

    if (patientIndex !== -1 && patientIndex !== undefined) {
      dispatch(setSelectedPatientIndex(patientIndex))

      patientCardRef?.current?.children?.[patientIndex]?.classList?.add(
        'patient-card-selected'
      )
    }
    patientCardRef?.current?.children?.[selectedPatientIndex]?.classList?.add(
      'patient-card-selected'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipdRegistrationFlagCount])

  const closeThopNotificationSocketConnection = () => {
    if (thopSocketRef?.current) {
      thopSocketRef?.current?.close(1000, 'Client disconnected')
      thopSocketRef.current = null
    }
  }

  useEffect(() => {
    // Scroll to the selected patient card
    if (
      selectedPatientIndex !== null &&
      criticalBedList[selectedPatientIndex]
    ) {
      const patientId = criticalBedList?.[selectedPatientIndex]?.patient_id
      dispatch(setPatientUHID(patientId))

      patientRefs?.current?.[selectedPatientIndex]?.scrollIntoView({
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatientIndex, criticalBedList])

  useEffect(() => {
    // Create Master patient details
    fetchAllBeds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientDetails?.masterPatientDetails?.length,
    patientDetails?.onlyLocations,
  ])

  useEffect(() => {
    // Reset view on location change
    if (isInitialRenderForPatient) {
      setIsInitialRenderForPatient(false)
      return
    }
    setSearchQuery('')
    dispatch(setHasPatientDataLoaded(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails?.currentLocation, isInitialRenderForPatient])

  useEffect(() => {
    if (
      newIpdUpdateMessage?.messageType ===
        thopNotificationAlertType?.newIpdRegistrationUpdate ||
      newIpdUpdateMessage?.messageType ===
        thopNotificationAlertType?.deleteIpdRegistration
    ) {
      if (
        newIpdUpdateMessage?.patientLocation === patientDetails?.currentLocation
      ) {
        dispatch(
          setIpdUpdateMessageLocation(newIpdUpdateMessage?.patientLocation)
        )
        dispatch(setIpdUpdateCount())
        if (newIpdUpdateMessage?.patientID === patientDetails?.patient_uhid) {
          clearPatientDetails()
        }
        const patientsList = patientDetails?.masterPatientDetails?.find(
          (subArray: { patientId: string }[]) =>
            subArray?.some(
              (patient: { patientId: string }) =>
                patient?.patientId === patientDetails?.patient_uhid
            )
        )
        const patientIndex = patientsList?.findIndex(
          (item: { patientId: string }) =>
            item?.patientId === patientDetails?.patient_uhid
        )
        dispatch(setSelectedPatientIndex(patientIndex))
      }
    }
    if (
      newIpdUpdateMessage?.messageType ===
      thopNotificationAlertType?.newIpdDataUpdate
    ) {
      if (newIpdUpdateMessage?.patientID === patientDetails?.patient_uhid) {
        dispatch(setIpdDataUpdateCount())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIpdUpdateMessage])

  useEffect(() => {
    // Remove highlighted patient effect on search
    if (isInitialRenderForSearch) {
      setIsInitialRenderForSearch(false)
      return
    }
    if (selectedPatientIndex !== null) {
      patientCardRef?.current?.children?.[
        selectedPatientIndex
      ]?.classList?.remove('patient-card-selected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails?.searchQuery, isInitialRenderForSearch])

  useEffect(() => {
    if (!selectedPatientIndex) {
      // Reset selected patient on route change
      dispatch(setSelectedPatientIndex(null))
    }
    fetchGetDoctors()
    fetchGetLocations()
    createThopNotificationSocketConnection()

    const thopSocketInterval = setInterval(() => {
      if (
        thopSocketRef?.current &&
        thopSocketRef?.current?.readyState === WebSocket.OPEN
      ) {
        thopSocketRef?.current?.send(JSON.stringify(emptyMessage))
      }
    }, Number(process.env.REACT_APP_THOP_SOCKET_DUMMY_MESSAGE_DELAY))

    return () => {
      clearInterval(thopSocketInterval)
      closeThopNotificationSocketConnection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='hospitals'>
      {patientDetails?.layout === hospitals?.noneLayout && (
        <div id='select-layout-container' className='select-layout'>
          <Select
            variant='outlined'
            id='select-layout-menu'
            className='select-layout-dropdown'
            value={patientDetails?.layout}
            onChange={(e: SelectChangeEvent) =>
              dispatch(setLayout(e.target.value))
            }
          >
            {layoutOptions?.map((item) => {
              return (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      )}
      {patientDetails?.layout !== hospitals?.noneLayout &&
        patientDetails?.layout !== hospitals?.patientDashboardLayout && (
          <Grid container columns={16}>
            <Grid item xs={16} sm={8} md={4} lg={3} xl={2}>
              <Select
                id='select-location-menu'
                className='select-location'
                IconComponent={CustomArrowIcon}
                value={patientDetails?.currentLocation}
                onChange={(e: SelectChangeEvent) => {
                  dispatch(setCurrentLocation(e.target.value))
                  dispatch(setSelectedPatientIndex(null))
                }}
              >
                {patientDetails?.onlyLocations?.map((item) => (
                  <MenuItem value={item} key={item}>
                    <div className='location-default-item'>
                      <FmdGoodOutlinedIcon className='location-icon' />
                      <span className='location-list-item'>{item}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={16} sm={8} md={4} lg={3} xl={2}>
              <TextField
                fullWidth
                id='search-patient'
                className='search-bar'
                placeholder={hospitals?.serachPlaceholder}
                value={patientDetails?.searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={16} sm={16} md={8} lg={10} xl={12}>
              <div id='patient-lists' className='patient-list-container'>
                <div
                  id='patient-list-item'
                  className='patient-section'
                  ref={patientCardRef}
                >
                  {filteredBedList?.length ? (
                    <>
                      {filteredBedList?.map((patient, index) => {
                        return (
                          <PatientCard
                            key={patient?.id}
                            patientRefs={patientRefs}
                            index={index}
                            selectedPatientIndex={selectedPatientIndex}
                            handleSelectedPatient={handleSelectedPatient}
                            patient={patient}
                          />
                        )
                      })}
                    </>
                  ) : (
                    <div
                      id='no-patient-available-text'
                      className='no-patient-available'
                    >
                      {noPatientAvailableText}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      {patientDetails?.layout === hospitals?.patientDashboardLayout ? (
        <Suspense fallback={<LoadingComp />}>
          <PatientDashboard thopSocketRef={thopSocketRef} />
        </Suspense>
      ) : null}
      {patientDetails?.patient_data_loaded &&
      patientDetails?.layout === hospitals?.vitalsLayout ? (
        <>
          <PatientDetails />
          <VitalsContainer />
        </>
      ) : null}
      {patientDetails?.patient_data_loaded &&
      patientDetails?.layout === hospitals?.patientDataLayout ? (
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PatientDetails />
            <PatientData thopSocketRef={thopSocketRef} />
          </Grid>
        </Grid>
      ) : null}
      {patientDetails?.patient_data_loaded &&
      patientDetails?.layout === hospitals?.intensivistsLayout ? (
        <>
          <PatientDetails />
          <Suspense fallback={<LoadingComp />}>
            <TreatmentAndMedications socketRef={thopSocketRef} />
          </Suspense>
        </>
      ) : null}
      {patientDetails?.patient_data_loaded &&
      patientDetails?.layout === hospitals?.defaultLayout ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <PatientDetails />
            <VitalsContainer />
            <PatientData thopSocketRef={thopSocketRef} />
            <Suspense fallback={<LoadingComp />}>
              <TreatmentAndMedications socketRef={thopSocketRef} />
            </Suspense>
          </Grid>
        </Grid>
      ) : null}
    </div>
  )
}

export default Hospitals
