type PatientDetails = {
  patientId: string
  patientName: string
  location: string
}

type LocationList = {
  id: string
  location: string
}

type Doctor = {
  id: number
  name: string
}

// Action Type
export const PATIENT_DATA_LOADED = 'PATIENT_DATA_LOADED'
export const PATIENT_NAME = 'PATIENT_NAME'
export const PATIENT_UHID = 'PATIENT_UHID'
export const PATIENT_DOB = 'PATIENT_DOB'
export const PATIENT_GENDER = 'PATIENT_GENDER'
export const PATIENT_FHIR_ID = 'PATIENT_FHIR_ID'
export const PATIENT_BED_NUMBER = 'PATIENT_BED_NUMBER'
export const PATIENT_ROOM_NUMBER = 'PATIENT_ROOM_NUMBER'
export const PATIENT_WARD_NAME = 'PATIENT_WARD_NAME'
export const SELECTED_PATIENT_INDEX = 'SELECTED_PATIENT_INDEX'
export const SELECTED_AMBULANCE_INDEX = 'SELECTED_AMBULANCE_INDEX'
export const LAYOUT = 'LAYOUT'
export const BED_ID = 'BED_ID'
export const BED_NUMBER = 'BED_NUMBER'
export const ADMISSION_DATE = 'ADMISSION_DATE'
export const MASTER_PATIENT_DETAILS = 'MASTER_PATIENT_DETAILS'
export const CURRENT_LOCATION = 'CURRENT_LOCATION'
export const IPD_UPDATE_MESSAGE_LOCATION = 'IPD_UPDATE_MESSAGE_LOCATION'
export const IS_AMBULANCE_CLICKED = 'IS_AMBULANCE_CLICKED'
export const LOCATIONS_LIST = 'LOCATIONS_LIST'
export const ONLY_LOCATIONS = 'ONLY_LOCATIONS'
export const SEARCH_QUERY = 'SEARCH_QUERY'
export const DOCTORS_LIST = 'DOCTORS_LIST'
export const THOP_NOTIFICATION_MESSAGES_ADD = 'THOP_NOTIFICATION_MESSAGES_ADD'
export const THOP_NOTIFICATION_MESSAGES_DELETE =
  'THOP_NOTIFICATION_MESSAGES_DELETE'
export const STATUS_UPDATE_COUNT = 'STATUS_UPDATE_COUNT'
export const IPD_UPDATE_COUNT = 'IPD_UPDATE_COUNT'
export const SELECTED_AMBULANCE = 'SELECTED_AMBULANCE'
export const AMBULANCE_LIST = 'AMBULANCE_LIST'
export const CLEAR_AMBULANCE_LIST = 'CLEAR_AMBULANCE_LIST'
export const IPD_DATA_UPDATE_COUNT = 'IPD_DATA_UPDATE_COUNT'

// Action
export const setHasPatientDataLoaded = (
  token: boolean
): { type: string; payload: boolean } => {
  return {
    type: PATIENT_DATA_LOADED,
    payload: token,
  }
}

export const setPatientName = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_NAME,
    payload: token,
  }
}

export const setPatientUHID = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_UHID,
    payload: token,
  }
}

export const setPatientDOB = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_DOB,
    payload: token,
  }
}

export const setPatientGender = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_GENDER,
    payload: token,
  }
}

export const setPatientFhirId = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_FHIR_ID,
    payload: token,
  }
}

export const setPatientBedNumber = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_BED_NUMBER,
    payload: token,
  }
}

export const setPatientRoomNumber = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_ROOM_NUMBER,
    payload: token,
  }
}

export const setPatientWardName = (
  token: string
): { type: string; payload: string } => {
  return {
    type: PATIENT_WARD_NAME,
    payload: token,
  }
}

export const setSelectedPatientIndex = (
  index: number | null
): { type: string; payload: number | null } => {
  return {
    type: SELECTED_PATIENT_INDEX,
    payload: index,
  }
}

export const setSelectedAmbulanceIndex = (
  index: number | null
): { type: string; payload: number | null } => {
  return {
    type: SELECTED_AMBULANCE_INDEX,
    payload: index,
  }
}

export const setLayout = (token: string): { type: string; payload: string } => {
  return {
    type: LAYOUT,
    payload: token,
  }
}

export const setBedId = (token: number): { type: string; payload: number } => {
  return {
    type: BED_ID,
    payload: token,
  }
}

export const setBedNumber = (
  token: string
): { type: string; payload: string } => {
  return {
    type: BED_NUMBER,
    payload: token,
  }
}

export const setAdmissionDate = (
  token: string
): { type: string; payload: string } => {
  return {
    type: ADMISSION_DATE,
    payload: token,
  }
}

export const setMasterPatientDetails = (
  token: PatientDetails[]
): { type: string; payload: PatientDetails[] } => {
  return {
    type: MASTER_PATIENT_DETAILS,
    payload: token,
  }
}

export const setCurrentLocation = (
  token: string
): { type: string; payload: string } => {
  return {
    type: CURRENT_LOCATION,
    payload: token,
  }
}

export const setIpdUpdateMessageLocation = (
  token: string
): { type: string; payload: string } => {
  return {
    type: IPD_UPDATE_MESSAGE_LOCATION,
    payload: token,
  }
}

export const setIsAmbulanceClicked = (
  isClicked: boolean
): { type: string; payload: boolean } => {
  return {
    type: IS_AMBULANCE_CLICKED,
    payload: isClicked,
  }
}

export const setLocationsList = (
  token: LocationList[]
): { type: string; payload: LocationList[] } => {
  return {
    type: LOCATIONS_LIST,
    payload: token,
  }
}

export const setOnlyLocations = (
  token: string[]
): { type: string; payload: string[] } => {
  return {
    type: ONLY_LOCATIONS,
    payload: token,
  }
}

export const setSearchQuery = (
  token: string
): { type: string; payload: string } => {
  return {
    type: SEARCH_QUERY,
    payload: token,
  }
}

export const setDoctors = (
  token: Doctor[]
): { type: string; payload: Doctor[] } => {
  return {
    type: DOCTORS_LIST,
    payload: token,
  }
}

export const addThopNotificationMessage = (
  message: string
): { type: string; payload: string } => {
  return {
    type: THOP_NOTIFICATION_MESSAGES_ADD,
    payload: message,
  }
}

export const deleteThopNotificationMessage = (
  index: number
): { type: string; payload: number } => {
  return {
    type: THOP_NOTIFICATION_MESSAGES_DELETE,
    payload: index,
  }
}

export const setStatusUpdateCount = (): { type: string } => {
  return {
    type: STATUS_UPDATE_COUNT,
  }
}
export const setIpdUpdateCount = (): { type: string } => {
  return {
    type: IPD_UPDATE_COUNT,
  }
}
export const setIpdDataUpdateCount = (): { type: string } => {
  return {
    type: IPD_DATA_UPDATE_COUNT,
  }
}

export const setSelectedAmbulance = (
  token: string
): { type: string; payload: string } => {
  return {
    type: SELECTED_AMBULANCE,
    payload: token,
  }
}

export const setAmbulanceList = (
  token: string
): { type: string; payload: string } => {
  return {
    type: AMBULANCE_LIST,
    payload: token,
  }
}

export const clearAmbulanceList = (): { type: string } => ({
  type: CLEAR_AMBULANCE_LIST,
})
