import { useDispatch } from 'react-redux'
import { createApiUrl } from './helperFunctions'
import { CRITICAL_BEDS_ID, intent } from './constants'
import { setLocationsList, setOnlyLocations } from '../redux/actions'

const useGetLocations = (): { fetchGetLocations: () => void } => {
  const dispatch = useDispatch()

  function fetchGetLocations() {
    const payload = {
      intent: intent?.criticalBedLoc,
    }

    fetch(createApiUrl(CRITICAL_BEDS_ID), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
        'Content-Type': 'application/json',
        version: 'WORKING',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to get locations')
        return res?.json()
      })
      .then((data) => {
        const onlyLocations = data?.criticalLoc
          ?.map((location) => {
            return location?.loc_name
          })
          ?.sort()

        const locationsList = data?.criticalLoc?.map((location) => {
          return {
            id: location?.loc_id,
            location: location?.loc_name,
          }
        })

        dispatch(setOnlyLocations(onlyLocations ?? []))
        dispatch(setLocationsList(locationsList ?? []))
      })
      .catch((err) => console.error(err))
  }

  return { fetchGetLocations }
}

export default useGetLocations
