import { configureStore } from '@reduxjs/toolkit'
import { loadingActionReducer } from './reducer'
import {
  LAYOUT,
  THOP_NOTIFICATION_MESSAGES_ADD,
  STATUS_UPDATE_COUNT,
  IPD_DATA_UPDATE_COUNT,
  IPD_UPDATE_MESSAGE_LOCATION,
} from './actions'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'

const reduxStateSyncConfig = {
  blacklist: [
    LAYOUT,
    THOP_NOTIFICATION_MESSAGES_ADD,
    STATUS_UPDATE_COUNT,
    IPD_DATA_UPDATE_COUNT,
    IPD_UPDATE_MESSAGE_LOCATION,
  ],
}

const stateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig)

const store = configureStore({
  reducer: loadingActionReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      stateSyncMiddleware
    ),
})

initMessageListener(store)

export { store }
